import ProductDetailPage from 'components/Product/ProductDetailPage';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import { getProduct } from 'store/reducers/productSlice';
import ShopError from './ShopError';

const Product = () => {
    const { category, productNumber } = useParams();
    const { product, loading, error, currentPathName, categories } = useAppSelector(
        ({ shop, product, assortment }) => ({
            ...shop,
            ...product,
            ...assortment,
        }),
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (productNumber) {
            const selectedCategoryCode = Object.values(categories).find((cat) => cat.name === category)?.code || '';
            dispatch(getProduct({ category: selectedCategoryCode, pnrOrSlug: productNumber }));
        }
    }, [dispatch, productNumber, category, categories]);

    if (loading) {
        return <Skeleton height={500} className="w-100 me-2" />;
    }

    if (error) {
        return <ShopError errorKey="product_not_found" />;
    }

    return product ? <ProductDetailPage /> : null;
};
export default Product;
