"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListVariationToJSON = exports.ListVariationFromJSONTyped = exports.ListVariationFromJSON = exports.instanceOfListVariation = void 0;
var runtime_1 = require("../runtime");
var ProductImage_1 = require("./ProductImage");
var TagType_1 = require("./TagType");
/**
 * Check if a given object implements the ListVariation interface.
 */
function instanceOfListVariation(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfListVariation = instanceOfListVariation;
function ListVariationFromJSON(json) {
    return ListVariationFromJSONTyped(json, false);
}
exports.ListVariationFromJSON = ListVariationFromJSON;
function ListVariationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'color': !(0, runtime_1.exists)(json, 'color') ? undefined : json['color'],
        'colorCode': !(0, runtime_1.exists)(json, 'colorCode') ? undefined : json['colorCode'],
        'variationNumber': !(0, runtime_1.exists)(json, 'variationNumber') ? undefined : json['variationNumber'],
        'image': !(0, runtime_1.exists)(json, 'image') ? undefined : (0, ProductImage_1.ProductImageFromJSON)(json['image']),
        'webColors': !(0, runtime_1.exists)(json, 'webColors') ? undefined : json['webColors'],
        'seasons': !(0, runtime_1.exists)(json, 'seasons') ? undefined : json['seasons'],
        'featured': !(0, runtime_1.exists)(json, 'featured') ? undefined : json['featured'],
        'tags': !(0, runtime_1.exists)(json, 'tags') ? undefined : (json['tags'] === null ? null : json['tags'].map(TagType_1.TagTypeFromJSON)),
    };
}
exports.ListVariationFromJSONTyped = ListVariationFromJSONTyped;
function ListVariationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'color': value.color,
        'colorCode': value.colorCode,
        'variationNumber': value.variationNumber,
        'image': (0, ProductImage_1.ProductImageToJSON)(value.image),
        'webColors': value.webColors,
        'seasons': value.seasons,
        'tags': value.tags === undefined ? undefined : (value.tags === null ? null : value.tags.map(TagType_1.TagTypeToJSON)),
    };
}
exports.ListVariationToJSON = ListVariationToJSON;
