"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TagPositionToJSON = exports.TagPositionFromJSONTyped = exports.TagPositionFromJSON = exports.TagPosition = void 0;
/**
 *
 * @export
 */
exports.TagPosition = {
    Badge: 0,
    Price: 1,
    ProductInfo: 2
};
function TagPositionFromJSON(json) {
    return TagPositionFromJSONTyped(json, false);
}
exports.TagPositionFromJSON = TagPositionFromJSON;
function TagPositionFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.TagPositionFromJSONTyped = TagPositionFromJSONTyped;
function TagPositionToJSON(value) {
    return value;
}
exports.TagPositionToJSON = TagPositionToJSON;
